

function Account() {
  return (
    <>
      <section className="container mx-auto px-24 lg:px-99 h-fit-screen grid place-items-center">
        <h3 className="text-38 lg:text-56 font-semibold mb-14 text-black leading-44 lg:leading-58">Account Page</h3>
      </section>
    </>
  );
}

export default Account;
